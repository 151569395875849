<template>
<div class="register-success">
  <div class="login-auth">
    <div class="columns" v-if="type === 'success'">
      <div class="column has-text-centered">
        <svg version="1.1" id="check-square" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="459px" height="459px" viewBox="0 0 459 459" style="enable-background:new 0 0 459 459;" xml:space="preserve">
          <g>
            <g id="check-box-outline">
              <path d="M124.95,181.05l-35.7,35.7L204,331.5l255-255l-35.7-35.7L204,260.1L124.95,181.05z M408,408H51V51h255V0H51
			               C22.95,0,0,22.95,0,51v357c0,28.05,22.95,51,51,51h357c28.05,0,51-22.95,51-51V204h-51V408z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <div class="columns" v-if="type === 'error'">
      <div class="column has-text-centered">
        <font-awesome-icon id="times-circle" :icon="['far', 'times-circle']" />
      </div>
    </div>
    <div class="columns" v-if="type === 'coming_soon'">
      <div class="column has-text-centered">
        <font-awesome-icon id="coming-soon-icon" :icon="['far', 'calendar-exclamation']" />
      </div>
    </div>
    <div class="columns" v-if="type === 'download_proposals'">
      <div class="column has-text-centered">
        <font-awesome-icon id="coming-soon-icon" :icon="['far', 'file-download']" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-8 is-offset-2 has-text-centered">
        <p class="subtitle sub-1">{{ title }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column is-8 is-offset-2 has-text-centered">
        <p class="subtitle sub-2">{{ subtitle }}</p>
      </div>
    </div>
    <div class="columns" v-if="!disableButtonBack">
      <div class="column is-8 is-offset-2 column-button">
          <button class="button btn-go-back" @click="volver()">
            <span><b>{{ $t('MESSAGE_BOX.GO_BACK') }}</b></span>
          </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "MessageBox",
  props: {
    title: String,
    subtitle: String,
    type: String,
    disableButtonBack: Boolean,
    downloadButton: Boolean
  },
  methods: {
    volver() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.login-auth {
  background-color: white;
  border-radius: .1rem;
  padding: 3.5rem 0;
}

.btn-go-back {
  background-color: $primary;
  color: white;
  height: 60px;
  width: 100%;
  border-radius: 10px;
}

.btn-go-back:hover {
  color: white;
}

#check-square {
  width: 5rem;
  height: 5rem;
  fill: $green;
}

svg#times-circle {
  width: 5rem;
  height: 5rem;
  color: $primary;
}

svg#coming-soon-icon {
  width: 6rem;
  height: 6rem;
  color: $primary;
}


.column-button {
  padding-top: 2.5rem;
  margin-bottom: -.5rem;
}

.sub-1 {
  font-size: 1.68rem;
  font-weight: 500;
  color: $color-text;
}

.sub-2 {
  font-size: 1rem;
}
</style>
